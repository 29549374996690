<template>
  <div class="main">
    <div class="banner">
      <div class="demo-carousel">
        <div class="demo-carousel-item">
          <div class="carousel_content">
            <h2>电商解决方案</h2>
            <p>为各种规模的企业提供灵活、安全、稳定、低成本的方案，帮助电商企业从容面对业务高峰、安全压力等，在市场竞争中脱颖而出。</p>
            <button>立即咨询</button>
          </div>
        </div>
      </div>
    </div>
    <div class="framework">
      <h2>方案架构</h2>
      <div class="flex">
        <div class="left">
          <div class="contents">
            <h3>场景方案</h3>
            <div class="flex_space_around">
              <div class="style">直播电商</div>
              <div class="style">社交电商</div>
              <div class="style">内容电商</div>
              <div class="style">垂直电商</div>
              <div class="style">……</div>
            </div>
          </div>
          <div class="contents">
            <h3>前台方案</h3>
            <div class="flex_space_around">
              <div class="style">产品展示</div>
              <div class="style">商品交易</div>
              <div class="style">会员自助</div>
              <div class="style">积分商城</div>
              <div class="style">……</div>
            </div>
          </div>
          <div style="display:flex">
            <div class="content center_left">
              <h3>用户端口</h3>
              <div class>
                <div class="style">产品展示</div>
                <div class="style">ios</div>
                <div class="style">Android</div>
                <div class="style">小程序</div>
                <div class="style">H5</div>
              </div>
            </div>
            <div class="content center">
              <h3>前台方案</h3>
              <div class="wrap">
                <div class="wrap_style">供应商管理</div>
                <div class="wrap_style">广告管理</div>
                <div class="wrap_style">发货管理</div>
                <div class="wrap_style">库存管理</div>
                <div class="wrap_style">采购管理</div>
                <div class="wrap_style">系统管理</div>
                <div class="wrap_style">积分管理</div>
                <div class="wrap_style">运营报表</div>
                <div class="wrap_style">订单管理</div>
                <div class="wrap_style">营销管理</div>
                <div class="wrap_style">内容管理</div>
                <div class="wrap_style">会员管理</div>
              </div>
            </div>
            <div class="content center_left">
              <h3>支付系统</h3>
              <div class>
                <div class="style">支付后台</div>
                <div class="style">账户信息</div>
                <div class="style">账户余额</div>
                <div class="style">充值管理</div>
                <div class="style">……</div>
              </div>
            </div>
          </div>
          <div class="content">
            <h3>安全方案</h3>
            <div class="flex_space_around">
              <div class="style">业务风控</div>
            </div>
          </div>
          <div class="contents">
            <h3>基础方案</h3>
            <div class="flex_space_around">
              <div class="style">业务上云</div>
              <div class="style">弹性大促</div>
              <div class="style">业务容灾</div>
              <div class="style">安全防护</div>
              <div class="style">……</div>
            </div>
          </div>
          <div class="content">
            <h3>基础设施</h3>
            <div class="flex_space_around">
              <div class="style">基于腾讯云全球的多个区域，多个可用区，提供包括计算、数据库、网络等多种产品能力</div>
            </div>
          </div>
        </div>
        <div class="right">
          <div class="content">
            <h3>基础方案</h3>
            <p>通过搭建基础设施为各规模的电商客户快速构建电 商平台</p>
            <p>针对大促、秒杀等活动对电商业务系统带来的挑战， 提供弹性，可扩展的基础设施和护航服务，帮助电 商客户从容应对挑战。</p>
            <p>通过多地网络专线接入能力，帮助客户快速将业务 扩展到公有云。</p>
          </div>
          <div class="content">
            <h3>后台方案</h3>
            <p>提供丰富的场景化方案能力，快速满足客户对多场 景的业务需求，例如大量图片的智能处理，直播和 短视频获客，精准推荐来提升用户复购率等诉求。</p>
          </div>

          <div class="content">
            <h3>安全方案</h3>
            <p>通过内部的安全能力积累，我们将为电商客户提供 从基础安全，网络安全到业务安全等全系列的安全 能力，为电商客户保驾护航。</p>
          </div>
        </div>
      </div>
    </div>
    <div class="case">
      <div class="top">
        <h2>客户案例</h2>
        <p>众博电商解决方案助力企业快速发展</p>
      </div>
      <div class="case_flex">
        <div class="left">
          <img
            src="../../assets/image/solution_log.png"
            alt
          >
        </div>
        <div class="right">
          <div class="right_top">
            <h2>百乐拼</h2>
          </div>
          <h3>社团团购</h3>
          <p>“百乐拼”社区团购项目主要“预售+自提”模式赋能社区便利店为社区家庭用户精选高性比的蔬菜、水果、肉禽蛋、酒水零食、家具厨卫、速冻食品、粮油调味等品类商品，满足家庭日常三餐所需。</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.flex {
  display: flex;
  justify-content: space-between;
}
.flex_space_around {
  display: flex;
  justify-content: space-around;
}

.banner {
  width: 100%;
  height: 540px;
}
.banner .ivu-carousel {
  width: 100%;
  height: 100%;
}
.banner .demo-carousel {
  width: 100%;
  height: 540px;
  background: url(../../assets/image/banner_solution.png) no-repeat center;
  background-size: cover;
  margin: 0 auto;
}
.banner .demo-carousel-item {
  width: 1200px;
  height: 540px;
  display: flex;
  align-items: center;
  margin: 0 auto;
}
.carousel_content {
  width: 586px;
  text-align: left;
}
.banner .demo-carousel-item h2 {
  font-size: 50px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffffff;
  line-height: 84px;
  margin-bottom: 10px;
}
.banner .demo-carousel-item p {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #ffffff;
  line-height: 33px;
  margin-bottom: 40px;
}
.banner .demo-carousel-item button {
  width: 185px;
  height: 55px;
  background: #ffffff;
  border-radius: 2px;
  border: none;
  font-size: 17px;
  font-family: PingFangSC-Regular, PingFang SC;
  color: #ff0610;
  line-height: 55px;
}
.banner .demo-img {
  width: 668px;
  height: auto;
}
.framework {
  width: 1200px;
  margin: 70px auto 0;
}
.framework h2 {
  font-size: 36px;
  color: #000;
  font-weight: 600;
}
.framework .left {
  width: 819px;
  height: 1020px;
}
.framework .left .content {
  width: 819px;
  border: 1px dashed #e0e0e0;
  margin-top: 10px;
}
.framework .left .center_left {
  width: 140px;
  height: 370px;
}
.framework .left .center {
  width: 519px;
  height: 370px;
  margin: 10px 10px 0;
}

.framework .left .content h3 {
  font-size: 18px;
  color: #ff0610;
  margin-top: 20px;
  text-align: center;
}
.framework .left .content .style {
  width: 779px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  color: #000;
  margin-top: 20px;
  margin-bottom: 34px;
  background: #ffe0e1;
}
.framework .left .contents h3 {
  font-size: 18px;
  color: #ff0610;
  margin-top: 20px;
  text-align: center;
}
.framework .left .contents {
  border: 1px dashed #e0e0e0;
  margin-top: 10px;
}
.framework .left .contents .style {
  width: 100px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  color: #000;
  margin-top: 20px;
  margin-bottom: 34px;
  background: #ffe0e1;
}
.framework .left .center_left .style {
  width: 100px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 20px auto 10px;
  background: #ffe0e1;
}
.framework .left .center .wrap {
  display: flex;
  flex-wrap: wrap;
  padding-left: 25px;
}
.framework .left .center .wrap .wrap_style {
  width: 100px;
  height: 50px;
  text-align: center;
  line-height: 50px;
  margin: 20px 20px 45px 0;
  background: #ffe0e1;
}

.framework .right {
  width: 371px;
  height: 1132px;
  border: 1px dashed #e0e0e0;
  margin-top: 10px;
}
.framework .right .content {
  width: 322px;
  margin-bottom: 50px;
  margin: 0 auto;
}
.framework .right .content h3 {
  font-size: 18px;
  color: #ff0610;
  margin: 20px auto 15px;
}
.framework .right .content p {
  font-size: 14px;
  color: #000;
  line-height: 20px;
  font-weight: 400;
  text-align: left;
  padding-bottom: 20px;
}

.case {
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 70px;
  margin-top: 70px;
}
.case h2 {
  font-weight: 600;
  font-size: 36px;
  color: #000;
  text-align: center;
}
.case p {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin-top: 16px;
  margin-bottom: 30px;
}
.case .case_flex {
  display: flex;
  box-shadow: 0px 2px 10px 0px rgba(232, 232, 232, 0.5);
}
.case .left {
  width: 600px;
  height: 560px;
}
.case .left img {
  width: 600px;
  height: 560px;
  /* border: 1px dashed #e0e0e0; */
}
.case .right {
  width: 600px;
  height: 560px;
  /* padding-top: 187px; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.case .right .right_top {
  width: 132px;
  height: 132px;
  background: #ffffff;
  border: 1px solid #f2f2f2;
  margin: 0 auto;
  text-align: center;
  line-height: 132px;
  border-radius: 50%;
}
.case .right .right_top h2 {
  color: #ff0610;
}
.case .right h3 {
  width: 56px;
  height: 20px;
  font-size: 14px;
  font-weight: 400;
  color: #4a4a4a;
  line-height: 20px;
  margin: 0 auto;
  margin-top: 54px;
}
.case .right p {
  width: 365px;
  height: 80px;
  font-size: 14px;
  font-weight: 400;
  color: #4a4a4a;
  line-height: 20px;
  margin: 0 auto;
  margin-top: 33px;
  text-align: left;
}
</style>
